import { __awaiter, __generator } from "tslib";
import { createRemoteConfigFetch } from '@amplitude/analytics-remote-config';
import { RequestMetadata } from '@amplitude/analytics-core';
var BrowserJoinedConfigGenerator = /** @class */function () {
  function BrowserJoinedConfigGenerator(localConfig) {
    this.config = localConfig;
    this.config.loggerProvider.debug('Local configuration before merging with remote config', JSON.stringify(this.config, null, 2));
  }
  BrowserJoinedConfigGenerator.prototype.initialize = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            _a = this;
            return [4 /*yield*/, createRemoteConfigFetch({
              localConfig: this.config,
              configKeys: ['analyticsSDK']
            })];
          case 1:
            _a.remoteConfigFetch = _b.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  BrowserJoinedConfigGenerator.prototype.generateJoinedConfig = function () {
    var _a, _b;
    var _c;
    return __awaiter(this, void 0, void 0, function () {
      var remoteConfig, _d, e_1;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            _e.trys.push([0, 3,, 4]);
            _d = this.remoteConfigFetch;
            if (!_d) return [3 /*break*/, 2];
            return [4 /*yield*/, this.remoteConfigFetch.getRemoteConfig('analyticsSDK', 'browserSDK', this.config.sessionId)];
          case 1:
            _d = _e.sent();
            _e.label = 2;
          case 2:
            remoteConfig = _d;
            this.config.loggerProvider.debug('Remote configuration:', JSON.stringify(remoteConfig, null, 2));
            if (remoteConfig && 'autocapture' in remoteConfig) {
              this.config.autocapture = remoteConfig.autocapture;
              this.config.defaultTracking = this.config.autocapture;
            }
            this.config.loggerProvider.debug('Joined configuration: ', JSON.stringify(this.config, null, 2));
            (_a = (_c = this.config).requestMetadata) !== null && _a !== void 0 ? _a : _c.requestMetadata = new RequestMetadata();
            this.config.requestMetadata.recordHistogram('remote_config_fetch_time', (_b = this.remoteConfigFetch) === null || _b === void 0 ? void 0 : _b.fetchTime);
            return [3 /*break*/, 4];
          case 3:
            e_1 = _e.sent();
            this.config.loggerProvider.error('Failed to fetch remote configuration because of error: ', e_1);
            return [3 /*break*/, 4];
          case 4:
            return [2 /*return*/, this.config];
        }
      });
    });
  };
  return BrowserJoinedConfigGenerator;
}();
export { BrowserJoinedConfigGenerator };
export var createBrowserJoinedConfigGenerator = function (localConfig) {
  return __awaiter(void 0, void 0, void 0, function () {
    var joinedConfigGenerator;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          joinedConfigGenerator = new BrowserJoinedConfigGenerator(localConfig);
          return [4 /*yield*/, joinedConfigGenerator.initialize()];
        case 1:
          _a.sent();
          return [2 /*return*/, joinedConfigGenerator];
      }
    });
  });
};
