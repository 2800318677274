const ENV_PROTOCOL = 'https';
const CLASSLINK_CLIENT_ID = 'c1657818278542478f888c944c197ad0bd2e933f08d1';
const URL = 'api.plasma.games';

export const environment = {
  name: 'Production',
  production: true,
  DOMAIN_WILDCARD: '.plasma.games',
  API_URL: `https://${URL}/graphql`,
  SCIOPS_API: `https://${URL}/`,
  plugins: [],
  SSO_URL_GOOGLE: `https://${URL}/auth/google/login`,
  SSO_URL_CLEVER: `https://clever.com/oauth/authorize?response_type=code&client_id=2c3467004487e0a56a73&redirect_uri=https://${URL}/auth/clever/login`,
  SSO_URL_CLASS_LINK: `https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=${ENV_PROTOCOL}://${URL}/auth/classlink/callback&client_id=${CLASSLINK_CLIENT_ID}&response_type=code`,
  SSO_CLEVER_ENABLED: true,
  GOOGLE_TAG_ID: 'GTM-KS2FQZB7',
  AMPLITUDE_ID: 'f7423da74f9759c4d391d8b868a82c94',
  AMPLITUDE_URL: `https://${URL}/amplitude`,
};
