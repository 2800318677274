/* eslint-disable no-restricted-globals */
import { finder } from './libs/finder';
import * as constants from './constants';
var SENSITIVE_TAGS = ['input', 'select', 'textarea'];
export var createShouldTrackEvent = function (autocaptureOptions, allowlist) {
  return function (actionType, element) {
    var _a, _b, _c;
    var pageUrlAllowlist = autocaptureOptions.pageUrlAllowlist,
      shouldTrackEventResolver = autocaptureOptions.shouldTrackEventResolver;
    /* istanbul ignore next */
    var tag = (_b = (_a = element === null || element === void 0 ? void 0 : element.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a);
    // window, document, and Text nodes have no tag
    if (!tag) {
      return false;
    }
    if (shouldTrackEventResolver) {
      return shouldTrackEventResolver(actionType, element);
    }
    if (!isPageUrlAllowed(window.location.href, pageUrlAllowlist)) {
      return false;
    }
    /* istanbul ignore next */
    var elementType = (element === null || element === void 0 ? void 0 : element.type) || '';
    if (typeof elementType === 'string') {
      switch (elementType.toLowerCase()) {
        case 'hidden':
          return false;
        case 'password':
          return false;
      }
    }
    /* istanbul ignore if */
    if (allowlist) {
      var hasMatchAnyAllowedSelector = allowlist.some(function (selector) {
        var _a;
        return !!((_a = element === null || element === void 0 ? void 0 : element.matches) === null || _a === void 0 ? void 0 : _a.call(element, selector));
      });
      if (!hasMatchAnyAllowedSelector) {
        return false;
      }
    }
    switch (tag) {
      case 'input':
      case 'select':
      case 'textarea':
        return actionType === 'change' || actionType === 'click';
      default:
        {
          /* istanbul ignore next */
          var computedStyle = (_c = window === null || window === void 0 ? void 0 : window.getComputedStyle) === null || _c === void 0 ? void 0 : _c.call(window, element);
          /* istanbul ignore next */
          if (computedStyle && computedStyle.getPropertyValue('cursor') === 'pointer' && actionType === 'click') {
            return true;
          }
          return actionType === 'click';
        }
    }
  };
};
export var isNonSensitiveString = function (text) {
  if (text == null) {
    return false;
  }
  if (typeof text === 'string') {
    var ccRegex = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
    if (ccRegex.test((text || '').replace(/[- ]/g, ''))) {
      return false;
    }
    var ssnRegex = /(^\d{3}-?\d{2}-?\d{4}$)/;
    if (ssnRegex.test(text)) {
      return false;
    }
  }
  return true;
};
export var isTextNode = function (node) {
  return !!node && node.nodeType === 3;
};
export var isNonSensitiveElement = function (element) {
  var _a, _b, _c;
  /* istanbul ignore next */
  var tag = (_b = (_a = element === null || element === void 0 ? void 0 : element.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a);
  var isContentEditable = element instanceof HTMLElement ? ((_c = element.getAttribute('contenteditable')) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === 'true' : false;
  return !SENSITIVE_TAGS.includes(tag) && !isContentEditable;
};
// Maybe this can be simplified with element.innerText, keep and manual concatenating for now, more research needed.
export var getText = function (element) {
  var text = '';
  if (isNonSensitiveElement(element) && element.childNodes && element.childNodes.length) {
    element.childNodes.forEach(function (child) {
      var childText = '';
      if (isTextNode(child)) {
        if (child.textContent) {
          childText = child.textContent;
        }
      } else {
        childText = getText(child);
      }
      text += childText.split(/(\s+)/).filter(isNonSensitiveString).join('').replace(/[\r\n]/g, ' ').replace(/[ ]+/g, ' ').substring(0, 255);
    });
  }
  return text;
};
export var getSelector = function (element, logger) {
  var _a, _b;
  var selector = '';
  try {
    selector = finder(element, {
      className: function (name) {
        return name !== constants.AMPLITUDE_VISUAL_TAGGING_HIGHLIGHT_CLASS;
      }
    });
    return selector;
  } catch (error) {
    if (logger) {
      var typedError = error;
      logger.warn("Failed to get selector with finder, use fallback strategy instead: ".concat(typedError.toString()));
    }
  }
  // Fall back to use tag, id, and class name, if finder fails.
  /* istanbul ignore next */
  var tag = (_b = (_a = element === null || element === void 0 ? void 0 : element.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a);
  if (tag) {
    selector = tag;
  }
  if (element.id) {
    selector = "#".concat(element.id);
  } else if (element.className) {
    var classes = element.className.split(' ').filter(function (name) {
      return name !== constants.AMPLITUDE_VISUAL_TAGGING_HIGHLIGHT_CLASS;
    }).join('.');
    if (classes) {
      selector = "".concat(selector, ".").concat(classes);
    }
  }
  return selector;
};
export var isPageUrlAllowed = function (url, pageUrlAllowlist) {
  if (!pageUrlAllowlist || !pageUrlAllowlist.length) {
    return true;
  }
  return pageUrlAllowlist.some(function (allowedUrl) {
    if (typeof allowedUrl === 'string') {
      return url === allowedUrl;
    }
    return url.match(allowedUrl);
  });
};
export var getAttributesWithPrefix = function (element, prefix) {
  return element.getAttributeNames().reduce(function (attributes, attributeName) {
    if (attributeName.startsWith(prefix)) {
      var attributeKey = attributeName.replace(prefix, '');
      var attributeValue = element.getAttribute(attributeName);
      if (attributeKey) {
        attributes[attributeKey] = attributeValue || '';
      }
    }
    return attributes;
  }, {});
};
export var isEmpty = function (value) {
  return value === undefined || value === null || typeof value === 'object' && Object.keys(value).length === 0 || typeof value === 'string' && value.trim().length === 0;
};
export var removeEmptyProperties = function (properties) {
  return Object.keys(properties).reduce(function (filteredProperties, key) {
    var value = properties[key];
    if (!isEmpty(value)) {
      filteredProperties[key] = value;
    }
    return filteredProperties;
  }, {});
};
export var getNearestLabel = function (element) {
  var parent = element.parentElement;
  if (!parent) {
    return '';
  }
  var labelElement = parent.querySelector(':scope>span,h1,h2,h3,h4,h5,h6');
  if (labelElement) {
    /* istanbul ignore next */
    var labelText = labelElement.textContent || '';
    return isNonSensitiveString(labelText) ? labelText : '';
  }
  return getNearestLabel(parent);
};
export var querySelectUniqueElements = function (root, selectors) {
  if (root && 'querySelectorAll' in root && typeof root.querySelectorAll === 'function') {
    var elementSet = selectors.reduce(function (elements, selector) {
      if (selector) {
        var selectedElements = Array.from(root.querySelectorAll(selector));
        selectedElements.forEach(function (element) {
          elements.add(element);
        });
      }
      return elements;
    }, new Set());
    return Array.from(elementSet);
  }
  return [];
};
// Similar as element.closest, but works with multiple selectors
export var getClosestElement = function (element, selectors) {
  if (!element) {
    return null;
  }
  /* istanbul ignore next */
  if (selectors.some(function (selector) {
    var _a;
    return (_a = element === null || element === void 0 ? void 0 : element.matches) === null || _a === void 0 ? void 0 : _a.call(element, selector);
  })) {
    return element;
  }
  /* istanbul ignore next */
  return getClosestElement(element === null || element === void 0 ? void 0 : element.parentElement, selectors);
};
// Returns the element properties for the given element in Visual Labeling.
export var getEventTagProps = function (element, logger) {
  var _a;
  var _b, _c;
  if (!element) {
    return {};
  }
  /* istanbul ignore next */
  var tag = (_c = (_b = element === null || element === void 0 ? void 0 : element.tagName) === null || _b === void 0 ? void 0 : _b.toLowerCase) === null || _c === void 0 ? void 0 : _c.call(_b);
  var selector = getSelector(element, logger);
  var properties = (_a = {}, _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_TAG] = tag, _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_TEXT] = getText(element), _a[constants.AMPLITUDE_EVENT_PROP_ELEMENT_SELECTOR] = selector, _a[constants.AMPLITUDE_EVENT_PROP_PAGE_URL] = window.location.href.split('?')[0], _a);
  return removeEmptyProperties(properties);
};
export var asyncLoadScript = function (url) {
  return new Promise(function (resolve, reject) {
    var _a;
    try {
      var scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.src = url;
      scriptElement.addEventListener('load', function () {
        resolve({
          status: true
        });
      }, {
        once: true
      });
      scriptElement.addEventListener('error', function () {
        reject({
          status: false,
          message: "Failed to load the script ".concat(url)
        });
      });
      /* istanbul ignore next */
      (_a = document.head) === null || _a === void 0 ? void 0 : _a.appendChild(scriptElement);
    } catch (error) {
      /* istanbul ignore next */
      reject(error);
    }
  });
};
export function generateUniqueId() {
  return "".concat(Date.now(), "-").concat(Math.random().toString(36).substr(2, 9));
}
export var filterOutNonTrackableEvents = function (event) {
  // Filter out changeEvent events with no target
  // This could happen when change events are triggered programmatically
  if (event.event.target === null || !event.closestTrackedAncestor) {
    return false;
  }
  return true;
};
